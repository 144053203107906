<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row no-gutters justify="center" class="mt-12 black--text">
      <v-col v-bind="main_card_width">
        <v-card color="transparent" class="pa-2">
          <v-card-text>
            <v-row class="rounded_row_white black--text pa-2 pb-12">
              <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.smAndDown">
                <v-img
                  class="service_image_position"
                  :src="require('@/assets/imgs/pages/support.png')"
                  alt="Icona assistenza"
                  contain
                  style=""
                  max-width="200"
                  :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="7">
                <h1 class="service_title">
                  Hai necessità di supporto informatico?
                </h1>
                <div class="small_screen_service_image">
                  <v-img
                    v-if="$vuetify.breakpoint.smAndDown"
                    :src="require('@/assets/imgs/pages/support.png')"
                    alt="Icona assistenza"
                    class="mt-2 mb-6"
                    contain
                    max-width="200"
                    :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                  ></v-img>
                </div>
                <h2 class="service_subtitle">Siamo qui per aiutarti!</h2>
                <p>
                  Ti supportiamo nella risoluzione delle problematiche più
                  comuni e in tutte quelle situazioni in cui un esperto può
                  essere realmente di aiuto.
                </p>
                <p>
                  Servizio di diagnostica e risoluzione di problemi, sia
                  software che hardware, manutenzione e amministrazione di tutti
                  i dispositivi informatici.
                </p>
                <p>
                  Crediamo che ogni dispositivo abbia un proprio ciclo di vita
                  durante il quale debba sempre operare al massimo delle sue
                  potenzialità.

                  <br />
                  Ed è per questo che gli utilizzatori devono poter ottenere il
                  massimo dalla tecnologia che impiegano.
                </p>
                <p>
                  L'attività di supporto tecnico può essere svolta sia in sede
                  che, quando possibile, in remoto attraverso tele-assistenza.
                </p>

                <!-- Servizi offerti -->
                <div class="mt-12 mb-12">
                  <h3 class="mb-2">
                    <strong> Alcuni servizi offerti: </strong>
                  </h3>
                  <ul>
                    <li>
                      Risoluzione di problemi su PC/Mac che ne impediscono il
                      corretto funzionamento
                    </li>
                    <li>
                      Diagnosi e risoluzione di problematiche hardware, software
                      e di rete
                    </li>
                    <li>
                      Analisi problemi di connettività sia domestica che
                      aziendale
                    </li>
                    <li>
                      Consulenza nell'identificazione di prodotti e soluzioni
                      informatiche per ogni esigenza
                    </li>
                    <li>
                      Formazione all'uso di dispositivi e delle pratiche
                      corrette per aumentare la produttività ed evitare rischi
                      come phishing e attacchi ransomware
                    </li>
                  </ul>
                </div>
              </v-col>

              <v-col cols="12" class="text-center">
                <contactCardButton
                  contact_type="support_user"
                  title="Hai bisogno di supporto informatico ?"
                />
              </v-col>

              <!-- <v-col cols="12" class="text-center">
                <v-card
                  small
                  text
                  color="white black--text"
                  :to="{ name: 'OurServicesAssistenzaConsulenza' }"
                  flat
                >
                  <v-card-actions>
                    <v-spacer />
                    Torna all'elenco dei servizi
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import contactCardButton from "@/components/contactCardButton";

export default {
  components: {
    contactCardButton,
  },
  metaInfo: {
    title: "Assistenza informatica",
    meta: [
      {
        name: "description",
        content:
          "Supporto e assistenza informatica, risoluzione problemi, assistenza remota e on-site. Vi aiutiamo a risolvere i problemi e a migliorare l'efficienza informatica.",
      },
      {
        name: "robots",
        content: "index",
      },
    ],
  },
};
</script>